// TEMP: For migration
import { Injectable } from '@angular/core';

import { BridgeConstants } from '../../../assets/structure-config';
import {
  MigrationDetails,
  NumberConversionMigration,
} from '../../models/migration';
import { CdAState } from '../../shared/configs/cda-state.config';
import { IdbTable } from '../../shared/configs/idb-table.config';
import { InspectionStatus } from '../../shared/configs/inspection-status.config';
import { NumberConversionStatus } from '../../shared/configs/number-conversion-status.config';
import { StructureType } from '../../shared/configs/structure-type.config';
import { CubeService } from '../cube/cube.service';
import { IdbService } from '../storage/idb.service';

interface RepeatSectionMigrationItems {
  list: any[];
  newKeys: string[];
  oldKeys: string[];
  radioKeys: string[];
}

@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  private convertedFieldsList: NumberConversionMigration[] = [];
  private dynamicModelStructure: any;
  private model: any = {};

  constructor(
    private cubeService: CubeService,
    private idbService: IdbService
  ) {}

  addLevel2ToModel(model: any): any {
    const l0ModelStructure = model[BridgeConstants.levels.l0];
    const campateNo =
      l0ModelStructure['Caratteristiche geometriche']['N° campate'];

    // INFO: Checking if already level 2 data is present in the model to avoid overwriting user data
    const isLevel2PresentInL0 =
      l0ModelStructure[BridgeConstants.levels.level2] &&
      Object.keys(l0ModelStructure[BridgeConstants.levels.level2]).length &&
      campateNo ===
        l0ModelStructure[BridgeConstants.levels.level2].level2List?.[0]?.[
          'N° campate'
        ];
    if (!isLevel2PresentInL0) {
      l0ModelStructure[BridgeConstants.levels.level2] = {};

      const level2CampateList = Array(campateNo)
        .fill(0)
        .map((x, i) => ({
          [BridgeConstants.partNo]: i + 1,
          [BridgeConstants.level2RiskSheets.strutturaleEFondazionale]: '',
          [BridgeConstants.level2RiskSheets.sismica]: '',
          [BridgeConstants.level2RiskSheets.frane]: '',
          [BridgeConstants.level2RiskSheets.idraulica]: '',
          CdA: '',
          modelstructure: {
            ...this.getLevel2Campata(i),
          },
        }));
      const level2List = [
        {
          'N° campate': campateNo,
          'Cda Status': CdAState.notCompleted,
          modelstructure: {
            campateCdaStatus: {
              'Data di ispezione': '',
              'Tecnico rilevatore': '',
              allCampataAreCalculated: false,
              rilevamento: '',
              "classeDiAttenzioneDell'Opera": CdAState.na,
              calculatedCdAValue: '',
              campateList: level2CampateList,
            },
          },
        },
      ];
      l0ModelStructure[BridgeConstants.levels.level2].level2List = level2List;
    }

    // Modifying old radio value to new value
    if (
      l0ModelStructure?.['Rete stradale']?.[
        'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
      ]?.[
        'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
      ] === 'SÌ'
    ) {
      l0ModelStructure['Rete stradale'][
        'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
      ][
        'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
      ] = 'SI';
    }

    let level2SharedFields = [];

    // l1
    const l1ModelStructure =
      l0ModelStructure.opereparzialilist?.[0]?.modelstructure;
    if (l1ModelStructure) {
      // Modifying old radio value to new value
      if (
        l1ModelStructure?.['Elementi di vulnerabilità sismica']?.[
          'Elementi di vulnerabilità sismica vedi 4 3 3 delle Linee Guida'
        ]?.['Assenti/Presenti'] === 'Assenti-Elementi di vulnerabilità sismica'
      ) {
        l1ModelStructure['Elementi di vulnerabilità sismica'][
          'Elementi di vulnerabilità sismica vedi 4 3 3 delle Linee Guida'
        ]['Assenti/Presenti'] = 'Assenti';
      }

      if (
        l1ModelStructure?.['Elementi di vulnerabilità sismica']?.[
          'Elementi di vulnerabilità sismica vedi 4 3 3 delle Linee Guida'
        ]?.['Assenti/Presenti'] ===
        'Presenti influenti sul comportamento sismico'
      ) {
        l1ModelStructure['Elementi di vulnerabilità sismica'][
          'Elementi di vulnerabilità sismica vedi 4 3 3 delle Linee Guida'
        ]['Assenti/Presenti'] =
          'Presenti (influenti sul comportamento sismico)';
      }

      if (
        l1ModelStructure?.['Rischio frane']?.[
          'Parametro della magnitudo su base volumetrica in metri cubi PM frane riconosciute / potenziali - radio'
        ]
      ) {
        let radioData =
          l1ModelStructure['Rischio frane'][
            'Parametro della magnitudo su base volumetrica in metri cubi PM frane riconosciute / potenziali - radio'
          ];
        switch (radioData) {
          case 'Estremamente/molto grande (> 106)':
            radioData = 'Estremamente grande';
            break;

          case 'Grande (2,5 ∙ 105 - 106)':
            radioData = 'Grande';
            break;

          case 'Media (2,5 ∙ 105 - 104)':
            radioData = 'Media';
            break;

          case 'Piccola (102 - 104)':
            radioData = 'Piccola';
            break;

          case 'Molto piccola (< 5 ∙ 102)':
            radioData = 'Molto piccola';
            break;
        }
      }

      if (
        l1ModelStructure?.['Rischio frane']?.[
          'Parametro della massima velocità attesa in funzione della tipologia di frana in atto o potenziale PV - radio'
        ]
      ) {
        let radioData =
          l1ModelStructure['Rischio frane'][
            'Parametro della massima velocità attesa in funzione della tipologia di frana in atto o potenziale PV - radio'
          ];
        switch (radioData) {
          case 'Estremamente molto rapida > 3 m/min':
            radioData = 'Estremamente/molto rapida';
            break;

          case 'Rapida (3 m/min - 1,8 m/h)':
            radioData = 'Rapida';
            break;

          case 'Moderata (1,8 m/h - 13 m/mese)':
            radioData = 'Moderata';
            break;

          case 'Lenta (13 m/mese - 1,6 m/anno)':
            radioData = 'Lenta';
            break;

          case 'Estremamente molto lenta < 1,6 m /anno':
            radioData = 'Estremamente lenta';
            break;
        }
      }

      // Setting campate edit status based on campata added in l1
      for (let campate of l1ModelStructure.campatelist) {
        l0ModelStructure[
          BridgeConstants.levels.level2
        ].level2List[0].modelstructure.campateCdaStatus.campateList[
          +campate[BridgeConstants.partNo] - 1
        ].isEditDisabled = false;
      }

      level2SharedFields = [
        {
          pathsKey: 'elementiDiVulnerabilitaSismica',
          updatedValue:
            l1ModelStructure?.['Elementi di vulnerabilità sismica']?.[
              'Elementi di vulnerabilità sismica vedi 4 3 3 delle Linee Guida'
            ]?.['Assenti/Presenti'] ?? '',
        },
      ];
    }

    // Setting inherited values from l0 and l1 to level 2 sheets
    level2SharedFields = level2SharedFields.concat([
      {
        pathsKey: 'accelerazioneDiPiccoAlSuolo',
        updatedValue:
          l0ModelStructure?.Localizzazione?.['Sismicità dell’area'] ?? '',
      },
      {
        pathsKey: 'alternativeStradali',
        updatedValue:
          l0ModelStructure?.['Rete stradale']?.[
            'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
          ]?.[
            'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
          ] ?? '',
      },
      {
        pathsKey: 'NCampate',
        updatedValue:
          l0ModelStructure?.['Caratteristiche geometriche']?.['N° campate'] ??
          '',
      },
    ]);

    for (const level2SharedField of level2SharedFields) {
      this.syncSharedModelDataForList(
        level2SharedField.pathsKey,
        level2SharedField.updatedValue,
        model
      );
    }

    const existingLevel2CampateList =
      model[BridgeConstants.levels.l0]?.[BridgeConstants.levels.level2]
        ?.level2List[0]?.modelstructure?.campateCdaStatus?.campateList;

    if (!existingLevel2CampateList) return model;

    existingLevel2CampateList.forEach((level2Campata) => {
      /* const baseValue =
        campata['modelstructure']?.[
          BridgeConstants.level2RiskSheets.strutturaleEFondazionale
        ]?.['Stima del livello di esposizione']?.['Alternative strudali'];

      if (baseValue) {
        const updatedPath = [
          'modelstructure',
          BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
          'Stima del livello di esposizione',
          'Alternative stradali',
        ];
        this.updateKeys([baseValue], [[...updatedPath]], campata);

        delete campata['modelstructure'][
          BridgeConstants.level2RiskSheets.strutturaleEFondazionale
        ]['Stima del livello di esposizione']['Alternative strudali'];
      } */

      // TODO: Check if the above code can replace the below code
      this.updateKeysForArray(
        level2Campata,
        [
          'modelstructure',
          'Strutturale e fondazionale',
          'Stima del livello di esposizione',
        ],
        'Alternative strudali',
        'Alternative stradali'
      );
      this.updateInheritedFieldsInLevel2Campata(level2Campata);

      if (l1ModelStructure) {
        const addedCampata = l1ModelStructure.campatelist.find(
          (l1Campata) =>
            l1Campata[BridgeConstants.partNo] ===
            level2Campata[BridgeConstants.partNo]
        );

        if (addedCampata) {
          const strutturaleEFondazionale =
            level2Campata['modelstructure'][
              BridgeConstants.level2RiskSheets.strutturaleEFondazionale
            ];
          addedCampata['modelstructure']['difettositaRelativaCalculation'] =
            this.calculateDifettositaRelativa(addedCampata?.modelstructure);

          if (!strutturaleEFondazionale) return model;

          const difettositaRelativaCalculation =
            addedCampata['modelstructure']['difettositaRelativaCalculation'];

          //Updating Difettosita relativa in strutturale e fondazionale
          strutturaleEFondazionale['Stima del livello di vulnerabilita'][
            'Difettosita relativa'
          ] = difettositaRelativaCalculation['Difettosita relativa'];

          //Updating Presenza di PS relativa in strutturale e fondazionale
          strutturaleEFondazionale['Stima del livello di vulnerabilita'][
            'Presenza di PS'
          ] = difettositaRelativaCalculation['Presenza di PS'] ? 'SI' : 'NO';
        }
      }
    });

    return model;
  }

  async migrateIdbMasterTables(): Promise<void> {
    this.idbService.migrateMasterTable(
      IdbTable.inspectionTemplateDefectsList,
      IdbTable.bridgeDefectsMaster
    );
    this.idbService.migrateMasterTable(
      IdbTable.inspectionTemplateMaster,
      IdbTable.inspectionTemplatesMaster
    );
    this.idbService.migrateMasterTable(
      IdbTable.tenantMaster,
      IdbTable.tenantsMaster
    );
    this.idbService.migrateMasterTable(
      IdbTable.tunnelDefectsList,
      IdbTable.tunnelDefectsMaster
    );
  }

  async migrateModel(
    model: any,
    dynamicModelStructure: any
  ): Promise<MigrationDetails> {
    this.model = model;
    this.convertedFieldsList = [];

    // If structure type is not bridge
    if (this.model.structure_type !== StructureType.bridge)
      return {
        isMigrated: false,
        model: this.model,
        convertedNumberFieldsList: this.convertedFieldsList,
      };

    this.dynamicModelStructure = this.cubeService.getDeepCopy(
      dynamicModelStructure
    );
    // Deleting l0 and l1 from latest template model since they are already present in the model and shouldn't be overwritten with empty data
    delete this.dynamicModelStructure[BridgeConstants.levels.l0];
    delete this.dynamicModelStructure[BridgeConstants.levels.l1];

    // Getting current version number
    const currentVersionParts =
      this.model.version?.split('.') ??
      this.model.outputVersions.output2.split('.').slice(1); // Getting from output paths when version field is not available since version field is present only for newer inspections
    const [currentMajorVersion] = currentVersionParts;

    // Migrating based on major version
    let isMigrated: boolean;
    switch (+currentMajorVersion) {
      case 6:
        await this.migrateBridgeInspectionV6();
        isMigrated = true;

        break;

      case 7:
        await this.migrateBridgeInspectionV7();
        isMigrated = true;

        break;

      case 8:
        await this.migrateBridgeInspectionV8();
        isMigrated = true;

        break;

      default:
        isMigrated = false;

        break;
    }

    return {
      convertedNumberFieldsList: this.convertedFieldsList,
      isMigrated: isMigrated,
      model: this.model,
    };
  }

  private addFieldsRemovedFromPreviousMigrationBug(): void {
    if (!this.model[BridgeConstants.levels.l0]['Localizzazione dei difetti']) {
      this.model[BridgeConstants.levels.l0]['Localizzazione dei difetti'] = {
        'Tipologia di indagine': '',
        'Tipologia elemento indagato': '',
        'Tipologie di difetti individuati': '',
      };
    }

    const l1ModelStructure =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure;
    if (
      l1ModelStructure &&
      !l1ModelStructure['Rischio frane']?.['Contesto geologico']?.['Unità 3']
    ) {
      l1ModelStructure['Rischio frane']['Contesto geologico']['Unità 3'] = '';
    }
  }

  private calculateDifettositaRelativa(campata: any): any {
    // Combining all defects from every defect sheets in campata
    const combinedDefectsList: any[] = []
      .concat(
        campata[`${BridgeConstants.levels.spalle}list`],
        campata[`${BridgeConstants.levels.pile}list`],
        campata[`${BridgeConstants.levels.archi}list`],
        campata[`${BridgeConstants.levels.impalcati}list`],
        campata[`${BridgeConstants.levels.giunti}list`],
        campata[`${BridgeConstants.levels.apparecchiSismici}list`],
        campata[`${BridgeConstants.levels.fileDiAppoggi}list`]
      )
      .flatMap(
        ({ modelstructure }) =>
          modelstructure['Scheda Ispezione Ponti di Livello 1'][
            'Scheda Ispezione Ponti di Livello 1 List1'
          ]
      );

    // Getting the unique and valid G values (1, 2, 3, 4, 5) from the defects sheet
    const uniqueGValues = [
      ...new Set(
        combinedDefectsList.map((defect) => defect?.G).filter(Boolean)
      ),
    ].sort();

    // Group by G value and calculate difettosita value for each group
    const difettositaRelativa = uniqueGValues.reduce(
      (gValueGroupDifettositaAcc: number, gValue: string) => {
        // Filtering defects based on G value and presence of K1 and K2 values
        const filteredDefectsList: any[] = combinedDefectsList.filter(
          (defect) =>
            defect &&
            defect.G == gValue &&
            defect['Estensione K1'] &&
            defect['Intensità K2']
        );

        // If no defects found, then return gValueGroupDifettositaAcc
        if (filteredDefectsList.length === 0) return gValueGroupDifettositaAcc;

        // Calculating total difettosita value for all the filtered defects
        const filteredDefectsDifettositaTotal: number =
          filteredDefectsList.reduce(
            (filteredDefectsDifettositaAcc: number, defect) => {
              const k1 = Number(defect['Estensione K1']) || 0;
              const k2 = Number(defect['Intensità K2']) || 0;
              const g = Number(defect.G) || 0;

              return filteredDefectsDifettositaAcc + k1 * k2 * g;
            },
            0
          );

        const gValueGroupDifettosita = ['1', '2', '3'].includes(gValue)
          ? filteredDefectsDifettositaTotal / filteredDefectsList.length // Use the average
          : filteredDefectsDifettositaTotal; // Use the total

        return gValueGroupDifettositaAcc + gValueGroupDifettosita;
      },
      0
    );

    // Checking if PS is present in any of the defects
    const presenzaDiPS = combinedDefectsList.some((defect) => defect?.PS);

    return {
      'Difettosita relativa': Number(difettositaRelativa.toFixed(2)) || 0,
      'Presenza di PS': presenzaDiPS,
    };
  }

  /**
   * Converts number fields in the given field list from incorrect format to correct format.
   * Removes anything other than digits, comma, or dot from the field values.
   * Updates the field values and adds the conversion details to the convertedFieldsList.
   *
   * @param fieldList - The list of fields to convert.
   * @returns void
   */
  private convertNumberFields(fieldList: any[]): void {
    const codiceIop = this.model[BridgeConstants.levels.l0]['Codice IOP'];
    for (let { field, fieldName, key, levelName } of fieldList) {
      if (!field[key]) continue;

      const initialValue = field[key].toString();
      let migrationStatus: NumberConversionStatus;
      // Removing anything other than digits, comma or dot
      let convertedValue = initialValue.replace(/[^\d,.-]/g, '');

      /* // INFO: Original
      // No comma or dot
      if (!convertedValue.includes(',') && !convertedValue.includes('.'))
        continue;

      // Only includes comma
      if (convertedValue.includes(',') && !convertedValue.includes('.')) {
        const commaSplitParts = convertedValue.split(',');

        // Multiple comma check
        if (commaSplitParts.length !== 2) {
          // Contains more than 1 comma
          convertedValue = commaSplitParts.join('');
          migrationStatus = NumberConversionStatus.converted;
        } else {
          // Contains only 1 comma (Ambiguous state)
          migrationStatus = NumberConversionStatus.ambiguous;
        }
      }

      // Only includes dot
      if (!convertedValue.includes(',') && convertedValue.includes('.')) {
        const dotSplitParts = convertedValue.split('.');

        // Multiple dot check
        if (dotSplitParts.length !== 2) {
          // Contains more than 1 dot
          convertedValue = dotSplitParts.join('');
          migrationStatus = NumberConversionStatus.converted;
        } else {
          // Contains only 1 dot (Ambiguous state)
          migrationStatus = NumberConversionStatus.ambiguous;
        }
      }

      // Includes comma & dot
      if (convertedValue.includes(',') && convertedValue.includes('.')) {
        const dotSplitParts = convertedValue.split('.');
        const commaSplitParts = convertedValue.split(',');

        if (dotSplitParts.length === 2 && commaSplitParts.length > 2) {
          // Multiple comma, single dot
          convertedValue = commaSplitParts.join('');
        } else if (commaSplitParts.length === 2 && dotSplitParts.length > 2) {
          // Multiple dot, single comma
          convertedValue = dotSplitParts.join('').replace(',', '.');
        } else {
          // Single dot and comma
          const dotIndex = convertedValue.indexOf('.');
          const commaIndex = convertedValue.indexOf(',');
          if (dotIndex > commaIndex) {
            convertedValue = commaSplitParts.join('');
          } else {
            convertedValue = dotSplitParts.join('').replace(',', '.');
          }
        }
        migrationStatus = NumberConversionStatus.converted;
      } */

      // INFO: Optimized
      const commaCount = (convertedValue.match(/,/g) || []).length;
      const dotCount = (convertedValue.match(/\./g) || []).length;
      switch (true) {
        case commaCount === 0 &&
          dotCount === 0 &&
          initialValue === convertedValue: // Contains no comma, no dot and no difference after string replace
          // Skip further processing
          continue;

        case commaCount === 0 &&
          dotCount === 0 &&
          initialValue !== convertedValue: // Contains no comma, no dot but have difference after string replace
          // For cases with values such as '12m' that gets converted to '12'
          migrationStatus = NumberConversionStatus.converted;

          break;

        case dotCount === 1 && commaCount === 1: // Contains single dot and single comma
          const dotIndex = convertedValue.indexOf('.');
          const commaIndex = convertedValue.indexOf(',');
          convertedValue =
            dotIndex > commaIndex
              ? convertedValue.replace(/,/g, '')
              : convertedValue.replace(/\./g, '').replace(',', '.');
          migrationStatus = NumberConversionStatus.converted;

          break;

        case commaCount === 1 && dotCount === 0: // Contains single comma, no dot (Ambiguous state)
          migrationStatus = NumberConversionStatus.ambiguous;

          break;

        case commaCount > 1 && dotCount === 0: // Contains multiple comma, no dot
          convertedValue = convertedValue.replace(/,/g, '');
          migrationStatus = NumberConversionStatus.converted;

          break;

        case commaCount > 1 && dotCount === 1: // Contains multiple comma, single dot
          convertedValue = convertedValue.replace(/,/g, '');
          migrationStatus = NumberConversionStatus.converted;

          break;

        case dotCount === 1 && commaCount === 0: // Contains single dot, no comma (Ambiguous state)
          migrationStatus = NumberConversionStatus.ambiguous;

          break;

        case dotCount > 1 && commaCount === 0: // Contains multiple dot, no comma
          convertedValue = convertedValue.replace(/\./g, '');
          migrationStatus = NumberConversionStatus.converted;

          break;

        case dotCount > 1 && commaCount === 1: // Contains multiple dot, single comma
          convertedValue = convertedValue.replace(/\./g, '').replace(',', '.');
          migrationStatus = NumberConversionStatus.converted;

          break;
      }

      // Below 2 case have % in their value originally, therefore it is getting added here.
      switch (key) {
        case 'ca Fattore di restringimento':
        case 'Cg fattore restringimento delle aree golenali':
          convertedValue = `${convertedValue}%`;

          break;
      }

      if (NumberConversionStatus.converted) field[key] = convertedValue;

      this.convertedFieldsList.push({
        codiceIop,
        levelName,
        fieldName,
        initialValue,
        convertedValue:
          migrationStatus === NumberConversionStatus.converted
            ? convertedValue
            : '',
        migrationStatus,
      });
    }
  }

  private getEmptyModelForMigration(): Record<string, any> {
    return {
      ...this.model,
      ...this.dynamicModelStructure,
      [BridgeConstants.levels.level2]: {},
      [BridgeConstants.levels.level2Campata]: {},
      [BridgeConstants.levels.apparecchiSismici]: {},
      [BridgeConstants.levels.archi]: {},
      [BridgeConstants.levels.fileDiAppoggi]: {},
      [BridgeConstants.levels.giunti]: {},
      [BridgeConstants.levels.impalcati]: {},
      [BridgeConstants.levels.pile]: {},
      [BridgeConstants.levels.spalle]: {},
      activeLevel: BridgeConstants.levels.l0,
    };
  }

  private getLevel2Campata(i: number): object {
    return {
      Campata: {
        [BridgeConstants.partNo]: i + 1,
      },
      [BridgeConstants.level2RiskSheets.strutturaleEFondazionale]: {
        'Stima del livello di pericolosita': {
          'Stima del livello di pericolosita calculated': '',
        },
        'Stima del livello di vulnerabilita': {
          'Livello di difettosita': '',
          'Stima del livello di vulnerabilita calculated': '',
        },
        'Stima del livello di esposizione': {
          'Luce media della campata calculated': '',
          'Stima del livello di esposizione calculated': '',
        },
        'Stima della classe di attenzione strutturale e fondazionale': {},
        isRiskSheetFilled: false,
      },
      [BridgeConstants.level2RiskSheets.sismica]: {
        'Valutazione del livello di pericolosità sismica': {
          'Categoria topografica calculated': '',
          'Categoria sottosuolo calculated': '',
        },
        'Stima del livello di vulnerabilità sismica': {
          'Luce massima campata m select': '',
        },
        'Stima del livello di esposizione sismica': {},
        'Stima della classe di attenzione sismica a livello territoriale': {},
        isRiskSheetFilled: false,
      },
      [BridgeConstants.level2RiskSheets.frane]: {
        'Fenomeno trascurabile': 'NO',
        'Stima Del Livello Di Suscettibilità Legato Al Rischio Frane': {},
        'Stima Del Livello Di Vulnerabilità Legato Al Rischio Frane': {},
        'Stima Del Livello Di Esposizione Legato Al Rischio Frane': {},
        'Stima Della Classe Di Attenzione Frane A Livello Territoriale': {},
        isRiskSheetFilled: true,
      },
      [BridgeConstants.level2RiskSheets.idraulica]: {
        'Fenomeno trascurabile': 'NO',
        'Stima della suscettibilita legeta al rischio idraulico': {
          'a Diametro pila': '',
        },
        'Stima della vulnerabilita legeta al rischio idraulico': {},
        'Stima dell espezione legata al rischio idraulico': {},
        'Stima della classe di attenzione rischio idraulico': {},
        isRiskSheetFilled: true,
      },
      [BridgeConstants.level2RiskSheets.globale]: {},
    };
  }

  private getPeriodoFromInspectionDate(): string {
    // Getting the starting date of the first date in Level 1
    const periodo =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure['Schede descrittive di ispezione ponti di Livello 1'][
        'Schede descrittive di ispezione ponti di Livello 1 section'
      ][0]['Data ispezione'] ?? '';

    if (Array.isArray(periodo)) {
      // Getting the starting date of the first date in Level 1
      return periodo.length > 0 ? periodo[0] : '';
    }

    return periodo;
  }

  private async migrateBridgeInspectionV6(): Promise<void> {
    // Defect table data movement from old defect file table to new split mode for tunnel and bridge
    await this.migrateDefectFiles(this.model.modelHash);

    this.model = this.getEmptyModelForMigration();

    this.saveAttentionClassDetailsToL0();
    this.saveDefectTotalCountToL0();
    this.model = this.addLevel2ToModel(this.model);
    this.migrateNumberFields();

    let baseValue: Record<string, any>, updatedPath: string[];

    // l0 -> Campagna di indagini per la valutazione dei difetti -> Elementi indagati tipologia e numero 1
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Campagna di indagini per la valutazione dei difetti'
      ]?.['Elementi indagati tipologia e numero 1'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Campagna di indagini per la valutazione dei difetti',
        'Elementi indagati tipologia e numero',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['Elementi indagati tipologia e numero 1 1'],
          baseValue['Elementi indagati tipologia e numero 1 2'],
          baseValue['Elementi indagati tipologia e numero 1 3'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Elementi indagati tipologia e numero 1'],
          [...updatedPath, 'Elementi indagati tipologia e numero 2'],
          [...updatedPath, 'Elementi indagati tipologia e numero 3'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Campagna di indagini per la valutazione dei difetti'
      ]['Elementi indagati tipologia e numero 1'];
    }

    // l0 -> Campagna di indagini per la valutazione dei difetti -> Indagini semi-distruttive/distruttive -> Tipologie di indagini 1
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Campagna di indagini per la valutazione dei difetti'
      ]?.['Indagini semi-distruttive/distruttive']?.['Tipologie di indagini 1'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Campagna di indagini per la valutazione dei difetti',
        'Indagini semi-distruttive/distruttive',
        'Tipologie di indagini',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['Tipologie di indagini 1 1'],
          baseValue['Tipologie di indagini 1 2'],
          baseValue['Tipologie di indagini 1 3'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Tipologie di indagini 1'],
          [...updatedPath, 'Tipologie di indagini 2'],
          [...updatedPath, 'Tipologie di indagini 3'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Campagna di indagini per la valutazione dei difetti'
      ]['Indagini semi-distruttive/distruttive']['Tipologie di indagini 1'];
    }

    // l0 -> Campagna di indagini per la valutazione dei difetti -> N° totale di indagini eseguite 1
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Campagna di indagini per la valutazione dei difetti'
      ]?.['N° totale di indagini eseguite 1'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Campagna di indagini per la valutazione dei difetti',
        'N° totale di indagini eseguite',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['N° totale di indagini eseguite 1 1'],
          baseValue['N° totale di indagini eseguite 1 2'],
          baseValue['N° totale di indagini eseguite 1 3'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'N° totale di indagini eseguite 1'],
          [...updatedPath, 'N° totale di indagini eseguite 2'],
          [...updatedPath, 'N° totale di indagini eseguite 3'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Campagna di indagini per la valutazione dei difetti'
      ]['N° totale di indagini eseguite 1'];
    }

    // l0 -> Documenti progettuali disponibili
    const documentiProgettualiDisponibili =
      this.model[BridgeConstants.levels.l0][
        'Documenti progettuali disponibili'
      ];

    if (documentiProgettualiDisponibili) {
      const documentiProgettualiDisponibiliPath = [
        'l0',
        'Documenti progettuali disponibili',
      ];

      // l0 -> Documenti progettuali disponibili -> Documentazione iniziale -> Piani e strumento di programmazione -> Piani e strumento di programmazione
      baseValue =
        documentiProgettualiDisponibili['Documentazione iniziale'][
          'Piani e strumento di programmazione'
        ]['Piani e strumento di programmazione'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documentazione iniziale',
          'Piani e strumento di programmazione',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili['Documentazione iniziale'][
          'Piani e strumento di programmazione'
        ]['Piani e strumento di programmazione'];
      }

      // l0 -> Documenti progettuali disponibili -> Documentazione iniziale -> Strumenti del finanziamento -> Strumenti del finanziamento
      baseValue =
        documentiProgettualiDisponibili['Documentazione iniziale'][
          'Strumenti del finanziamento'
        ]['Strumenti del finanziamento'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documentazione iniziale',
          'Strumenti del finanziamento',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili['Documentazione iniziale'][
          'Strumenti del finanziamento'
        ]['Strumenti del finanziamento'];
      }

      // l0 -> Documenti progettuali disponibili -> Documenti inerenti il collaudo -> Allegati alla relazione di collaudo -> Allegati alla relazione di collaudo8
      baseValue =
        documentiProgettualiDisponibili['Documenti inerenti il collaudo'][
          'Allegati alla relazione di collaudo'
        ]['Allegati alla relazione di collaudo8'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documenti inerenti il collaudo',
          'Allegati alla relazione di collaudo',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Documenti inerenti il collaudo'
        ]['Allegati alla relazione di collaudo'][
          'Allegati alla relazione di collaudo8'
        ];
      }

      // l0 -> Documenti progettuali disponibili -> Documenti inerenti il collaudo -> Relazione di collaudo -> Relazione di collaudo8
      baseValue =
        documentiProgettualiDisponibili['Documenti inerenti il collaudo'][
          'Relazione di collaudo'
        ]['Relazione di collaudo8'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documenti inerenti il collaudo',
          'Relazione di collaudo',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Documenti inerenti il collaudo'
        ]['Relazione di collaudo']['Relazione di collaudo8'];
      }

      // l0 -> Documenti progettuali disponibili -> Documenti inerenti la direzione dei lavori -> Documenti contrattuali -> Documenti contrattuali4
      baseValue =
        documentiProgettualiDisponibili[
          'Documenti inerenti la direzione dei lavori'
        ]['Documenti contrattuali']['Documenti contrattuali4'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documenti inerenti la direzione dei lavori',
          'Documenti contrattuali',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Documenti inerenti la direzione dei lavori'
        ]['Documenti contrattuali']['Documenti contrattuali4'];
      }

      // l0 -> Documenti progettuali disponibili -> Documenti inerenti la direzione dei lavori -> Grafici allegati alla contabilità dell’appalto -> Grafici allegati alla contabilità dell’appalto4
      baseValue =
        documentiProgettualiDisponibili[
          'Documenti inerenti la direzione dei lavori'
        ]['Grafici allegati alla contabilità dell’appalto'][
          'Grafici allegati alla contabilità dell’appalto4'
        ];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documenti inerenti la direzione dei lavori',
          'Grafici allegati alla contabilità dell’appalto',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Documenti inerenti la direzione dei lavori'
        ]['Grafici allegati alla contabilità dell’appalto'][
          'Grafici allegati alla contabilità dell’appalto4'
        ];
      }

      // l0 -> Documenti progettuali disponibili -> Documenti inerenti la realizzazione -> Documenti contabili -> Documenti contabili5
      baseValue =
        documentiProgettualiDisponibili['Documenti inerenti la realizzazione'][
          'Documenti contabili'
        ]['Documenti contabili5'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documenti inerenti la realizzazione',
          'Documenti contabili',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Documenti inerenti la realizzazione'
        ]['Documenti contabili']['Documenti contabili5'];
      }

      // l0 -> Documenti progettuali disponibili -> Documenti inerenti la realizzazione -> Documenti contrattuali -> Documenti contrattuali5
      baseValue =
        documentiProgettualiDisponibili['Documenti inerenti la realizzazione'][
          'Documenti contrattuali'
        ]['Documenti contrattuali5'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Documenti inerenti la realizzazione',
          'Documenti contrattuali',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Documenti inerenti la realizzazione'
        ]['Documenti contrattuali']['Documenti contrattuali5'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto definitivo/esecutivo -> Documenti descrittivi -> Documenti descrittivi2
      baseValue =
        documentiProgettualiDisponibili['Progetto definitivo/esecutivo'][
          'Documenti descrittivi'
        ]['Documenti descrittivi2'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto definitivo/esecutivo',
          'Documenti descrittivi',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili['Progetto definitivo/esecutivo'][
          'Documenti descrittivi'
        ]['Documenti descrittivi2'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto definitivo/esecutivo -> Documenti grafici -> Documenti grafici2
      baseValue =
        documentiProgettualiDisponibili['Progetto definitivo/esecutivo'][
          'Documenti grafici'
        ]['Documenti grafici2'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto definitivo/esecutivo',
          'Documenti grafici',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili['Progetto definitivo/esecutivo'][
          'Documenti grafici'
        ]['Documenti grafici2'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto definitivo/esecutivo -> Documenti inerenti l’iter di approvazione -> Documenti inerenti l’iter di approvazione1
      baseValue =
        documentiProgettualiDisponibili['Progetto definitivo/esecutivo'][
          'Documenti inerenti l’iter di approvazione'
        ]['Documenti inerenti l’iter di approvazione1'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto definitivo/esecutivo',
          'Documenti inerenti l’iter di approvazione',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili['Progetto definitivo/esecutivo'][
          'Documenti inerenti l’iter di approvazione'
        ]['Documenti inerenti l’iter di approvazione1'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto esecutivo/cantierabile -> Documenti descrittivi -> Documenti descrittivi3
      baseValue =
        documentiProgettualiDisponibili['Progetto esecutivo/cantierabile'][
          'Documenti descrittivi'
        ]['Documenti descrittivi3'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto esecutivo/cantierabile',
          'Documenti descrittivi',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Progetto esecutivo/cantierabile'
        ]['Documenti descrittivi']['Documenti descrittivi3'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto esecutivo/cantierabile -> Documenti grafici -> Documenti grafici3
      baseValue =
        documentiProgettualiDisponibili['Progetto esecutivo/cantierabile'][
          'Documenti grafici'
        ]['Documenti grafici3'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto esecutivo/cantierabile',
          'Documenti grafici',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Progetto esecutivo/cantierabile'
        ]['Documenti grafici']['Documenti grafici3'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto esecutivo/cantierabile -> Documenti inerenti l’iter di approvazione -> Documenti inerenti l’iter di approvazione3
      baseValue =
        documentiProgettualiDisponibili['Progetto esecutivo/cantierabile'][
          'Documenti inerenti l’iter di approvazione'
        ]['Documenti inerenti l’iter di approvazione3'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto esecutivo/cantierabile',
          'Documenti inerenti l’iter di approvazione',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Progetto esecutivo/cantierabile'
        ]['Documenti inerenti l’iter di approvazione'][
          'Documenti inerenti l’iter di approvazione3'
        ];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto preliminare/di massima -> Documenti descrittivi -> Documenti descrittivi
      baseValue =
        documentiProgettualiDisponibili['Progetto preliminare/di massima'][
          'Documenti descrittivi'
        ]['Documenti descrittivi'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto preliminare/di massima',
          'Documenti descrittivi',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Progetto preliminare/di massima'
        ]['Documenti descrittivi']['Documenti descrittivi'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto preliminare/di massima -> Documenti grafici -> Documenti grafici1
      baseValue =
        documentiProgettualiDisponibili['Progetto preliminare/di massima'][
          'Documenti grafici'
        ]['Documenti grafici1'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto preliminare/di massima',
          'Documenti grafici',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Progetto preliminare/di massima'
        ]['Documenti grafici']['Documenti grafici1'];
      }

      // l0 -> Documenti progettuali disponibili -> Progetto preliminare/di massima -> Documenti inerenti l’iter di approvazione -> Documenti inerenti l’iter di approvazione
      baseValue =
        documentiProgettualiDisponibili['Progetto preliminare/di massima'][
          'Documenti inerenti l’iter di approvazione'
        ]['Documenti inerenti l’iter di approvazione'];

      if (baseValue) {
        updatedPath = [
          ...documentiProgettualiDisponibiliPath,
          'Progetto preliminare/di massima',
          'Documenti inerenti l’iter di approvazione',
          'radio',
        ];
        this.updateKeys([baseValue], [[...updatedPath]]);

        delete documentiProgettualiDisponibili[
          'Progetto preliminare/di massima'
        ]['Documenti inerenti l’iter di approvazione'][
          'Documenti inerenti l’iter di approvazione'
        ];
      }

      // l0 -> Documenti progettuali disponibili -> Interventi di incremento del grado di sicurezza -> Intervento
      this.updateRepeatSectionKeys({
        list: documentiProgettualiDisponibili[
          'Interventi di incremento del grado di sicurezza'
        ]['Intervento'],
        newKeys: [
          'Documenti descrittivi Intervento',
          'Documenti grafici Intervento',
        ],
        oldKeys: [
          'Documenti descrittivi Intervento 1',
          'Documenti grafici Intervento 1',
        ],
        radioKeys: ['Documenti descrittivi15', 'Documenti grafici15'],
      });

      // l0 -> Documenti progettuali disponibili -> Interventi di manutenzione -> Manutenzione
      this.updateRepeatSectionKeys({
        list: documentiProgettualiDisponibili['Interventi di manutenzione'][
          'Manutenzione'
        ],
        newKeys: [
          'Documenti contrattuali e contabili Manutenzione',
          'Documenti descrittivi Manutenzione',
          'Documenti grafici Manutenzione',
        ],
        oldKeys: [
          'Documenti contrattuali e contabili Manutenzione 1',
          'Documenti descrittivi Manutenzione 1',
          'Documenti grafici Manutenzione 1',
        ],
        radioKeys: [
          'Documenti contrattuali e contabili9',
          'Documenti descrittivi9',
          'Documenti grafici9',
        ],
      });

      // l0 -> Documenti progettuali disponibili -> Progetto di incremento del grado di sicurezza -> Progetto
      this.updateRepeatSectionKeys({
        list: documentiProgettualiDisponibili[
          'Progetto di incremento del grado di sicurezza'
        ]['Progetto'],
        newKeys: [
          'Documenti descrittivi Progetto',
          'Documenti grafici Progetto',
        ],
        oldKeys: [
          'Documenti descrittivi Progetto 1',
          'Documenti grafici Progetto 1',
        ],
        radioKeys: ['Documenti descrittivi13', 'Documenti grafici13'],
      });

      // l0 -> Documenti progettuali disponibili -> Segnalazioni -> Segnalazione
      this.updateRepeatSectionKeys({
        list: documentiProgettualiDisponibili['Segnalazioni']['Segnalazione'],
        newKeys: [
          'Documenti descrittivi con date Segnalazione',
          'Documenti grafici con date Segnalazione',
        ],
        oldKeys: [
          'Documenti descrittivi con date Segnalazione 1',
          'Documenti grafici con date Segnalazione 1',
        ],
        radioKeys: [
          'Documenti descrittivi con date11',
          'Documenti grafici con date11',
        ],
      });

      // l0 -> Documenti progettuali disponibili -> Varianti in corso d’opera -> Varianti
      this.updateRepeatSectionKeys({
        list: documentiProgettualiDisponibili['Varianti in corso d’opera'][
          'Varianti'
        ],
        newKeys: [
          'Documenti contrattuali e contabili Variante',
          'Documenti descrittivi Variante',
          'Documenti grafici Variante',
        ],
        oldKeys: [
          'Documenti contrattuali e contabili Variante',
          'Documenti descrittivi Variante',
          'Documenti grafici Variante ',
        ],
        radioKeys: [
          'Documenti contrattuali e contabili6',
          'Documenti descrittivi6',
          'Documenti grafici6',
        ],
      });
    }

    // l0 -> Localizzazione dei difetti 1
    baseValue =
      this.model[BridgeConstants.levels.l0]['Localizzazione dei difetti 1'];
    if (baseValue) {
      updatedPath = ['l0', 'Localizzazione dei difetti'];
      this.updateKeys(
        [
          baseValue,
          baseValue['Tipologia di indagine 1'],
          baseValue['Tipologia elemento indagato 1'],
          baseValue['Tipologie di difetti individuati 1'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Tipologia di indagine'],
          [...updatedPath, 'Tipologia elemento indagato'],
          [...updatedPath, 'Tipologie di difetti individuati'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Localizzazione dei difetti 1'
      ];
    }

    // l0 -> Prelievo di materiale di iniezione -> Descrizione 2
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]?.['Descrizione 2'];
    if (baseValue) {
      updatedPath = ['l0', 'Prelievo di materiale di iniezione', 'Descrizione'];
      this.updateKeys([baseValue], [[...updatedPath]]);

      // Not deleting the old key now since the next 3 sections are dependent on it
    }

    // l0 -> Prelievo di materiale di iniezione -> Descrizione 2 -> Descrizione 2
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]?.['Descrizione 2']?.['Descrizione 2'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Prelievo di materiale di iniezione',
        'Descrizione',
        'Descrizione',
      ];
      this.updateKeys(
        [baseValue, baseValue['Descrizione 2 1'], baseValue['Descrizione 2 2']],
        [
          [...updatedPath],
          [...updatedPath, 'Descrizione 1'],
          [...updatedPath, 'Descrizione 2'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]['Descrizione 2']['Descrizione 2'];
    }

    // l0 -> Prelievo di materiale di iniezione -> Descrizione 2 -> Elementi indagati tipologia e numero 2
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]?.['Descrizione 2']?.['Elementi indagati tipologia e numero 2'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Prelievo di materiale di iniezione',
        'Descrizione',
        'Elementi indagati tipologia e numero',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['Elementi indagati tipologia e numero 2 1'],
          baseValue['Elementi indagati tipologia e numero 2 2'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Elementi indagati tipologia e numero 1'],
          [...updatedPath, 'Elementi indagati tipologia e numero 2'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]['Descrizione 2']['Elementi indagati tipologia e numero 2'];
    }

    // l0 -> Prelievo di materiale di iniezione -> Descrizione 2 -> N° totale di prelievi eseguiti 2
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]?.['Descrizione 2']?.['N° totale di prelievi eseguiti 2'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Prelievo di materiale di iniezione',
        'Descrizione',
        'N° totale di prelievi eseguiti',
      ];
      this.updateKeys(
        [baseValue, baseValue['N° totale di prelievi eseguiti 2 1']],
        [[...updatedPath], [...updatedPath, 'N° totale di prelievi eseguiti 1']]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Prelievo di materiale di iniezione'
      ]['Descrizione 2']['N° totale di prelievi eseguiti 2'];
    }

    delete this.model[BridgeConstants.levels.l0][
      'Prelievo di materiale di iniezione'
    ]?.['Descrizione 2'];

    // l0 -> Prove chimiche -> Prove chimiche -> N° totale di prove eseguite 2
    baseValue =
      this.model[BridgeConstants.levels.l0]['Prove chimiche']?.[
        'Prove chimiche'
      ]?.['N° totale di prove eseguite 2'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Prove chimiche',
        'Prove chimiche',
        'N° totale di prove eseguite',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['N° totale di prove eseguite 2 1'],
          baseValue['N° totale di prove eseguite 2 2'],
          baseValue['N° totale di prove eseguite 2 3'],
          baseValue['N° totale di prove eseguite 2 4'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'N° totale di prove eseguite 1'],
          [...updatedPath, 'N° totale di prove eseguite 2'],
          [...updatedPath, 'N° totale di prove eseguite 3'],
          [...updatedPath, 'N° totale di prove eseguite 4'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0]['Prove chimiche'][
        'Prove chimiche'
      ]['N° totale di prove eseguite 2'];
    }

    // l0 -> Prove chimiche -> Prove chimiche -> Tipologia 2
    baseValue =
      this.model[BridgeConstants.levels.l0]['Prove chimiche']?.[
        'Prove chimiche'
      ]?.['Tipologia 2'];
    if (baseValue) {
      updatedPath = ['l0', 'Prove chimiche', 'Prove chimiche', 'Tipologia'];
      this.updateKeys(
        [
          baseValue,
          baseValue[' 4'],
          baseValue[' Tipologia 2 2'],
          baseValue['Tipologia 2 1'],
          baseValue['Tipologia 2 3'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Tipologia 4'],
          [...updatedPath, 'Tipologia 2'],
          [...updatedPath, 'Tipologia 1'],
          [...updatedPath, 'Tipologia 3'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0]['Prove chimiche'][
        'Prove chimiche'
      ]['Tipologia 2'];
    }

    // l0 -> Valutazione dello stato di tensione del filo o del calcestruzzo -> Descrizione 1
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Valutazione dello stato di tensione del filo o del calcestruzzo'
      ]?.['Descrizione 1'];
    if (baseValue) {
      updatedPath = [
        'l0',
        'Valutazione dello stato di tensione del filo o del calcestruzzo',
        'Descrizione',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['Descrizione']['Descrizione 1 1'],
          baseValue['Descrizione']['Descrizione 1 2'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Descrizione', 'Descrizione 1'],
          [...updatedPath, 'Descrizione', 'Descrizione 2'],
        ]
      );

      delete this.model[BridgeConstants.levels.l0][
        'Valutazione dello stato di tensione del filo o del calcestruzzo'
      ]['Descrizione 1'];
    }

    // l0 -> Valutazione dello stato di tensione del filo o del calcestruzzo -> Rispondenza con il materiale di progetto originale 1
    baseValue =
      this.model[BridgeConstants.levels.l0][
        'Valutazione dello stato di tensione del filo o del calcestruzzo'
      ]?.['Rispondenza con il materiale di progetto originale 1'];
    updatedPath = [
      'l0',
      'Valutazione dello stato di tensione del filo o del calcestruzzo',
      'Rispondenza con il materiale di progetto originale',
    ];
    if (baseValue) this.updateKeys([baseValue], [[...updatedPath]]);

    delete this.model[BridgeConstants.levels.l0][
      'Valutazione dello stato di tensione del filo o del calcestruzzo'
    ]?.['Rispondenza con il materiale di progetto originale 1'];

    // l1 -> Localizzazione (only for imported inspection)
    if (this.model.status === InspectionStatus.imported) {
      this.model[BridgeConstants.levels.l1][
        'Localizzazione Fenomeni di frana'
      ] = this.cubeService.getDeepCopy(
        this.model[BridgeConstants.levels.l0]['Localizzazione']
      );
    }

    // l1
    const l1ModelStructure =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure;
    if (!l1ModelStructure) return;

    const l1ModelStructurePath = [
      'l0',
      'opereparzialilist',
      '0',
      'modelstructure',
    ];

    // l1 -> Localizzazione
    if (!l1ModelStructure['Localizzazione Fenomeni di frana']) {
      l1ModelStructure['Localizzazione Fenomeni di frana'] =
        this.cubeService.getDeepCopy(l1ModelStructure['Localizzazione']);
    }

    // Not deleting the old key since it is also required

    // l1 -> Rischio frane -> Contesto geologico
    baseValue =
      l1ModelStructure['Rischio frane']?.['Contesto geologico']?.['3'];
    if (baseValue) {
      updatedPath = [
        ...l1ModelStructurePath,
        'Rischio frane',
        'Contesto geologico',
        'Unità 3',
      ];
      this.updateKeys([baseValue], [[...updatedPath]]);

      delete l1ModelStructure['Rischio frane']['Contesto geologico']['3'];
    }

    // l1 -> Rischio frane -> Tipologia di fenomeno 1
    baseValue = l1ModelStructure['Rischio frane']?.['Tipologia di fenomeno 1'];
    if (baseValue) {
      updatedPath = [
        ...l1ModelStructurePath,
        'Rischio frane',
        'Tipologia di fenomeno',
      ];
      this.updateKeys(
        [
          baseValue,
          baseValue['Accertato 1'],
          baseValue['Tipologia di fenomeno - radio 1'],
        ],
        [
          [...updatedPath],
          [...updatedPath, 'Accertato'],
          [...updatedPath, 'Tipologia di fenomeno - radio'],
        ]
      );

      delete l1ModelStructure['Rischio frane']['Tipologia di fenomeno 1'];
    }

    // l1 -> Rischio idraulico -> Tipologia di fenomeno 2
    baseValue =
      l1ModelStructure['Rischio idraulico']?.['Tipologia di fenomeno 2'];
    if (baseValue) {
      updatedPath = [
        ...l1ModelStructurePath,
        'Rischio idraulico',
        'Tipologia di fenomeno',
      ];
      this.updateKeys(
        [baseValue, baseValue['Tipologia di fenomeno - radio 2']],
        [[...updatedPath], [...updatedPath, 'Tipologia di fenomeno - radio']]
      );

      delete l1ModelStructure['Rischio idraulico']['Tipologia di fenomeno 2'];
    }

    // l1 -> Tipologia strutturale -> Empty-checkbox
    baseValue = l1ModelStructure['Tipologia strutturale']?.['Empty-checkbox'];
    if (baseValue) {
      updatedPath = [
        ...l1ModelStructurePath,
        'Tipologia strutturale',
        'Arco in acciaio',
      ];
      this.updateKeys([baseValue], [[...updatedPath]]);

      delete l1ModelStructure['Tipologia strutturale']['Empty-checkbox'];
    }

    if (l1ModelStructure.Caso1) {
      // l1 -> Caso1 -> GUARDIAVIA
      this.updateCasoSectionKeys(
        l1ModelStructure.Caso1.GUARDIAVIA,
        'Checkbox',
        'checkbox'
      );
    }

    if (l1ModelStructure[BridgeConstants.partsList]) {
      // l1 -> campatelist
      l1ModelStructure[BridgeConstants.partsList].forEach(
        ({ modelstructure: modelStructure }) => {
          const campataDefectsList = [].concat(
            modelStructure[`${BridgeConstants.levels.spalle}list`],
            modelStructure[`${BridgeConstants.levels.pile}list`],
            modelStructure[`${BridgeConstants.levels.archi}list`],
            modelStructure[`${BridgeConstants.levels.impalcati}list`],
            modelStructure[`${BridgeConstants.levels.fileDiAppoggi}list`],
            modelStructure[`${BridgeConstants.levels.giunti}list`],
            modelStructure[`${BridgeConstants.levels.apparecchiSismici}list`]
          );

          this.updateBridgeDefectsKeys(campataDefectsList);
          this.updateCodiceIopInDefects(campataDefectsList);
          this.updateFotoNameInDefects(campataDefectsList);
        }
      );
    }

    // INFO: temporary function to add the fields that got removed due to bug in migration to avoid report generation issues, although the input data is lost and cannot be recovered
    this.addFieldsRemovedFromPreviousMigrationBug();
  }

  private async migrateBridgeInspectionV7(): Promise<void> {
    this.model = this.getEmptyModelForMigration();

    this.saveAttentionClassDetailsToL0();
    this.saveDefectTotalCountToL0();
    this.model = this.addLevel2ToModel(this.model);
    this.migrateNumberFields();

    let baseValue: Record<string, any>, updatedPath: string[];

    // l1 -> Localizzazione (only for imported inspection)
    if (this.model.status === InspectionStatus.imported) {
      this.model[BridgeConstants.levels.l1][
        'Localizzazione Fenomeni di frana'
      ] = this.cubeService.getDeepCopy(
        this.model[BridgeConstants.levels.l0]['Localizzazione']
      );
    }

    // l1
    const l1ModelStructure =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure;
    if (!l1ModelStructure) return;

    const l1ModelStructurePath = [
      'l0',
      'opereparzialilist',
      '0',
      'modelstructure',
    ];

    // l1 -> Tipologia strutturale -> Empty-checkbox
    baseValue = l1ModelStructure['Tipologia strutturale']?.['Empty-checkbox'];
    if (baseValue) {
      updatedPath = [
        ...l1ModelStructurePath,
        'Tipologia strutturale',
        'Arco in acciaio',
      ];
      this.updateKeys([baseValue], [[...updatedPath]]);

      delete l1ModelStructure['Tipologia strutturale']['Empty-checkbox'];
    }

    if (l1ModelStructure[BridgeConstants.partsList]) {
      // l1 -> campatelist
      l1ModelStructure[BridgeConstants.partsList].forEach(
        ({ modelstructure: modelStructure }) => {
          const campataDefectsList = [].concat(
            modelStructure[`${BridgeConstants.levels.spalle}list`],
            modelStructure[`${BridgeConstants.levels.pile}list`],
            modelStructure[`${BridgeConstants.levels.archi}list`],
            modelStructure[`${BridgeConstants.levels.impalcati}list`],
            modelStructure[`${BridgeConstants.levels.fileDiAppoggi}list`],
            modelStructure[`${BridgeConstants.levels.giunti}list`],
            modelStructure[`${BridgeConstants.levels.apparecchiSismici}list`]
          );

          this.updateCodiceIopInDefects(campataDefectsList);
        }
      );
    }
  }

  private async migrateBridgeInspectionV8(): Promise<void> {
    this.model = this.getEmptyModelForMigration();

    // level2 -> (only for imported inspection)
    if (this.model.status === InspectionStatus.imported) {
      this.model = this.addLevel2ToModel(this.model);
    }
    this.migrateNumberFields();
    this.saveDefectTotalCountToL0();

    // l1
    const level1CampateList =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure?.[BridgeConstants.partsList];
    if (level1CampateList) {
      // l1 -> campatelist
      level1CampateList.forEach(({ modelstructure: modelStructure }) => {
        const campataDefectsList = [].concat(
          modelStructure[`${BridgeConstants.levels.spalle}list`],
          modelStructure[`${BridgeConstants.levels.pile}list`],
          modelStructure[`${BridgeConstants.levels.archi}list`],
          modelStructure[`${BridgeConstants.levels.impalcati}list`],
          modelStructure[`${BridgeConstants.levels.fileDiAppoggi}list`],
          modelStructure[`${BridgeConstants.levels.giunti}list`],
          modelStructure[`${BridgeConstants.levels.apparecchiSismici}list`]
        );

        this.updateCodiceIopInDefects(campataDefectsList);
      });
    }

    const level2CampateList =
      this.model[BridgeConstants.levels.l0]?.[BridgeConstants.levels.level2]
        ?.level2List[0]?.modelstructure?.campateCdaStatus?.campateList;

    if (level2CampateList) {
      // Level 2 -> Campata -> Strutturale e fondazionale -> Stima del livello di esposizione -> Alternative strudali
      level2CampateList.forEach((campata) => {
        /* const baseValue =
        campata['modelstructure']?.[
          BridgeConstants.level2RiskSheets.strutturaleEFondazionale
        ]?.['Stima del livello di esposizione']?.['Alternative strudali'];

      if (baseValue) {
        const updatedPath = [
          'modelstructure',
          BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
          'Stima del livello di esposizione',
          'Alternative stradali',
        ];
        this.updateKeys([baseValue], [[...updatedPath]], campata);

        delete campata['modelstructure'][
          BridgeConstants.level2RiskSheets.strutturaleEFondazionale
        ]['Stima del livello di esposizione']['Alternative strudali'];
      } */

        // TODO: Check if the above code can replace the below code
        this.updateKeysForArray(
          campata,
          [
            'modelstructure',
            BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
            'Stima del livello di esposizione',
          ],
          'Alternative strudali',
          'Alternative stradali'
        );
      });
    }
  }

  private async migrateDefectFiles(modelHash: string) {
    // Getting files from new table with the same modelhash to see if the files from old table is already migrated.
    const defectFileListInNewTable = await this.idbService.getUploadedFilesList(
      { modelHash },
      StructureType.bridge
    );

    // Skipping functionality if files are already migrated to avoid duplication
    if (defectFileListInNewTable.length) return;

    const defectFileList =
      await this.idbService.getUploadedFilesListForMigration({ modelHash });

    const newDefectFileList = defectFileList.map((defectFile) => {
      return {
        defect: defectFile.component,
        defectElementListName: defectFile.componentListName,
        defectElementRowIndex: defectFile.componentRowIndex,
        defectTemplateTableRowIndex: defectFile.rowIndex,
        file: defectFile.file,
        fotoName: defectFile.fotoName,
        modelHash,
      };
    });

    await this.idbService.addUploadedFiles(
      newDefectFileList,
      StructureType.bridge
    );
  }

  private migrateNumberFields(): void {
    const l0Model = this.model[BridgeConstants.levels.l0];

    // Level 0 fields
    const l0Fields = [
      {
        field: l0Model?.['Localizzazione'] ?? {},
        fieldName: 'Sismicità dell’area',
        key: 'Sismicità dell’area',
        levelName: 'Level 0',
      },
      {
        field: l0Model?.['Caratteristiche geometriche'] ?? {},
        fieldName: 'N° campate',
        key: 'N° campate',
        levelName: 'Level 0',
      },

      // Level 0 - Cavi Post Tesi
      {
        field:
          l0Model?.['Campagna di indagini per la valutazione dei difetti']?.[
            'N° totale di indagini eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Tipologie di indagini - N° Totale di indagini eseguite',
        key: 'N° totale di indagini eseguite 1',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Campagna di indagini per la valutazione dei difetti']?.[
            'N° totale di indagini eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Tipologie di indagini - N° Totale di indagini eseguite',
        key: 'N° totale di indagini eseguite 2',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Campagna di indagini per la valutazione dei difetti']?.[
            'N° totale di indagini eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Tipologie di indagini - N° Totale di indagini eseguite',
        key: 'N° totale di indagini eseguite 3',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.[
            'Valutazione dello stato di tensione del filo o del calcestruzzo'
          ]?.['Descrizione']?.['N° totale di misurazioni eseguite'] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Valutazione dello stato di tensione del filo o del calcestruzzo - N° Totale di misurazioni eseguite',
        key: 'N° totale di misurazioni eseguite 1',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Prelievo di materiale di iniezione']?.['Descrizione']?.[
            'N° totale di prelievi eseguiti'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Prelievo di materiale di iniezione - N° Totale di prelievi eseguiti',
        key: 'N° totale di prelievi eseguiti 1',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Prove chimiche']?.['Prove chimiche']?.[
            'N° totale di prove eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Prove chimiche - N° Totale di prove eseguite',
        key: 'N° totale di prove eseguite 1',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Prove chimiche']?.['Prove chimiche']?.[
            'N° totale di prove eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Prove chimiche - N° Totale di prove eseguite',
        key: 'N° totale di prove eseguite 2',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Prove chimiche']?.['Prove chimiche']?.[
            'N° totale di prove eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Prove chimiche - N° Totale di prove eseguite',
        key: 'N° totale di prove eseguite 3',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
      {
        field:
          l0Model?.['Prove chimiche']?.['Prove chimiche']?.[
            'N° totale di prove eseguite'
          ] ?? {},
        fieldName:
          'CAMPAGNA DI INDAGINI PER LA VALUTAZIONE DEI DIFETTI - Prove chimiche - N° Totale di prove eseguite',
        key: 'N° totale di prove eseguite 4',
        levelName: 'Level 0 - Cavi Post Tesi',
      },
    ];
    this.convertNumberFields(l0Fields);

    const l1Model =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure;
    if (l1Model) {
      // Level 1 fields
      const l1Fields = [
        // Level 1 Descriptive Sheet Fields
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Luce complessiva (spalla - spalla) [m]',
          key: 'Luce complessiva spalla - spalla m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Luce media campata [m]',
          key: 'Luce media campata m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Luce massima campata [m]',
          key: 'Luce massima campata m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Lunghezza sbalzo soletta sx [m]',
          key: 'Lunghezza sbalzo soletta sx m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Lunghezza sbalzo soletta dx [m]',
          key: 'Lunghezza sbalzo soletta dx m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName: 'RILIEVO CARATTERISTICHE GEOMETRICHE - N° campate',
          key: 'N° campate',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Altezza max pile [m]',
          key: 'Altezza max pile m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Rilievo caratteristiche geometriche'] ?? {},
          fieldName:
            'RILIEVO CARATTERISTICHE GEOMETRICHE - Larghezza impalcato [m] (dalla sede stradale)',
          key: 'Larghezza impalcato m',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field:
            l1Model?.['Apparecchi di appoggio']?.['Presenti - input'] ?? {},
          fieldName:
            'APPARECCHI DI APPOGGIO - Presenti - N° apparecchi rilevabili',
          key: 'n apparecchi rilevabili',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field:
            l1Model?.['Apparecchi di appoggio']?.['Presenti - input'] ?? {},
          fieldName: 'APPARECCHI DI APPOGGIO - Presenti - N° apparecchi totali',
          key: 'n apparecchi tot',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Apparecchi sismici']?.['Presenti - input'] ?? {},
          fieldName:
            'APPARECCHI DI SISMICI - Presenti - N° apparecchi rilevabili',
          key: 'n apparecchi rilevabili',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field: l1Model?.['Apparecchi sismici']?.['Presenti - input'] ?? {},
          fieldName: 'APPARECCHI DI SISMICI - Presenti - N° apparecchi totali',
          key: 'n apparecchi tot',
          levelName: 'Level 1 Descriptive Sheet',
        },
        {
          field:
            l1Model?.['Informazioni Ispezione']?.[
              'Possibilità di accedere al di sotto del ponte'
            ] ?? {},
          fieldName:
            'INFORMAZIONI ISPEZIONE - Parziale - N° campate completamente ispezionate',
          key: 'n campate completamente ispezionate',
          levelName: 'Level 1 Descriptive Sheet',
        },

        // Frane/Idraulica Form Fields
        {
          field: l1Model?.['Localizzazione Fenomeni di frana'] ?? {},
          fieldName: 'Ispezioni precedenti - Numero',
          key: 'Numero',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio frane'] ?? {},
          fieldName:
            'RISCHIO FRANE - Dati generali (frane riconosciute / potenziali) - Quota orlo superiore zona distacco [m]',
          key: 'Quota orlo superiore zona distacco m',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio frane'] ?? {},
          fieldName:
            'RISCHIO FRANE - Dati generali (frane riconosciute / potenziali) - Area totale [m2]',
          key: 'Area totale m2',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio frane'] ?? {},
          fieldName:
            'RISCHIO FRANE - Dati generali (frane riconosciute / potenziali) - Volume massa [m3]',
          key: 'Volume massa m3',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio frane'] ?? {},
          fieldName:
            'RISCHIO FRANE - Dati generali (frane riconosciute / potenziali) - Quota ponte o viadotto [m]',
          key: 'Quota ponte o viadotto m',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio frane'] ?? {},
          fieldName:
            'RISCHIO FRANE - Dati generali (frane riconosciute / potenziali) - Profondità superficie di distacco [m]',
          key: 'Profondità superficie di distacco m',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio idraulico']?.['Tipologia di alveo'] ?? {},
          fieldName:
            "RISCHIO FRANE - Fenomeni di erosione - Larghezza complessiva dell'alveo inciso occupata dall'ingombro di pile e spalle (wa,l)",
          key: 'Larghezza complessiva dell’alveo inciso occupata dall’ingombro di pile e spalle wa,l',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio idraulico']?.['Tipologia di alveo'] ?? {},
          fieldName:
            "RISCHIO FRANE - Fenomeni di erosione - Larghezza complessiva dell'alveo inciso a monete del ponte (wa)",
          key: 'Larghezza complessiva dell’alveo inciso a monte del ponte wa',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio idraulico']?.['Tipologia di alveo'] ?? {},
          fieldName:
            'RISCHIO FRANE - Fenomeni di erosione - Larghezza complessiva delle golene occupata dai rilevati di accesso, dalle spalle e dalle pile (wg,l)',
          key: 'Larghezza complessiva delle golene occupata dai rilevati di accesso, dalle spalle e dalle pile wg,l',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field: l1Model?.['Rischio idraulico']?.['Tipologia di alveo'] ?? {},
          fieldName:
            'RISCHIO FRANE - Fenomeni di erosione - Larghezza complessiva delle golene a monte del ponte (wg)',
          key: 'Larghezza complessiva delle golene a monte del ponte wg',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field:
            l1Model?.['Rischio idraulico']?.['Caratteristiche Alveo'] ?? {},
          fieldName:
            'RISCHIO FRANE - Caratteristiche Alveo - Massima profondità di scavo (ds)',
          key: 'Massima profondità di scavo ds',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field:
            l1Model?.['Rischio idraulico']?.['Caratteristiche Alveo'] ?? {},
          fieldName:
            "RISCHIO FRANE - Caratteristiche Alveo - Profondità di posa del piano di fondazione rispetto all'alveo (df)",
          key: 'Profondità di posa del piano di fondazione rispetto all’alveo df',
          levelName: 'Frane/Idraulica Form',
        },
        {
          field:
            l1Model?.['Rischio idraulico']?.['Caratteristiche Alveo'] ?? {},
          fieldName:
            'RISCHIO FRANE - Caratteristiche Alveo - Dimensioni del bacino idrografico (kmq)',
          key: 'Dimensioni del bacino idrografico kmq',
          levelName: 'Frane/Idraulica Form',
        },

        // Level 1 - Elementi Accessori
        {
          field:
            l1Model?.['Scheda Ispezione Ponti di Livello 1']?.[
              'Scheda Ispezione Ponti di Livello 1 List1'
            ] ?? {},
          fieldName: 'Scheda Ispezione Ponti di Livello 1 - N° Corsie',
          key: 'N° corsie',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field:
            l1Model?.['Scheda Ispezione Ponti di Livello 1']?.[
              'Scheda Ispezione Ponti di Livello 1 List1'
            ] ?? {},
          fieldName:
            'Scheda Ispezione Ponti di Livello 1 - Larghezza carreggiata',
          key: 'Larghezza carreggiata',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field:
            l1Model?.['Scheda Ispezione Ponti di Livello 1']?.[
              'Scheda Ispezione Ponti di Livello 1 List1'
            ] ?? {},
          fieldName:
            'Scheda Ispezione Ponti di Livello 1 - Larghezza marciapiede',
          key: 'Larghezza marciapiede',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field:
            l1Model?.['Scheda Ispezione Ponti di Livello 1']?.[
              'Scheda Ispezione Ponti di Livello 1 List1'
            ] ?? {},
          fieldName:
            'Scheda Ispezione Ponti di Livello 1 - Altezza marciapiede',
          key: 'Altezza marciapiede',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field: l1Model?.['Caso1']?.['GUARDIAVIA']?.[12] ?? {},
          fieldName:
            'CASO 1 MARCIAPIEDE ASSENTE - GUARDIAVIA - El.Acc_13g_1 - Altezza (cm)',
          key: 'checkbox',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field: l1Model?.['Caso2']?.['GUARDIAVIA']?.[12] ?? {},
          fieldName:
            'CASO 2 MARCIAPIEDE SORMONTABILE - GUARDIAVIA - El.Acc_13g_1 - Altezza (cm)',
          key: 'checkbox',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field: l1Model?.['Caso2']?.['PARAPETTO']?.[12] ?? {},
          fieldName:
            'CASO 2 MARCIAPIEDE SORMONTABILE - PARAPETTO(Guardiavia se 1 assente) - El.Acc_13g_2 - Altezza (cm)',
          key: 'checkbox',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field: l1Model?.['Caso3']?.['GUARDIAVIA']?.[14] ?? {},
          fieldName:
            'CASO 3 MARCIAPIEDE NON SORMONTABILE - GUARDIAVIA - El.Acc_13g_1 - Altezza (cm)',
          key: 'checkbox',
          levelName: 'Level 1 - Elementi Accessori',
        },
        {
          field: l1Model?.['Caso3']?.['PARAPETTO']?.[12] ?? {},
          fieldName:
            'CASO 3 MARCIAPIEDE NON SORMONTABILE - PARAPETTO(Guardiavia se 1 assente) - El.Acc_13g_2 - Altezza (cm)',
          key: 'checkbox',
          levelName: 'Level 1 - Elementi Accessori',
        },
      ];
      this.convertNumberFields(l1Fields);

      const tipologiaElementiStrutturaliList =
        l1Model?.['Tipologia elementi strutturali'] ?? [];
      const tipologiaElementiStrutturaliImpalcatoList =
        l1Model?.['Tipologia elementi strutturali impalcato'] ?? [];

      for (let tipologiaElementiStrutturali of tipologiaElementiStrutturaliList) {
        const tipologiaElementiStrutturaliFields = [
          {
            field: tipologiaElementiStrutturali ?? {},
            fieldName:
              'RILIEVO CARATTERISTICHE GEOMETRICHE - Tipologia elementi strutturali - Impalcato/n elementi',
            key: 'impalcato',
            levelName: 'Level 1 Descriptive Sheet',
          },
          {
            field: tipologiaElementiStrutturali ?? {},
            fieldName:
              'RILIEVO CARATTERISTICHE GEOMETRICHE - Tipologia elementi strutturali - Pile/n elementi',
            key: 'pile',
            levelName: 'Level 1 Descriptive Sheet',
          },
          {
            field: tipologiaElementiStrutturali ?? {},
            fieldName:
              'RILIEVO CARATTERISTICHE GEOMETRICHE - Tipologia elementi strutturali - Spalle/n elementi',
            key: 'spalle',
            levelName: 'Level 1 Descriptive Sheet',
          },
        ];
        this.convertNumberFields(tipologiaElementiStrutturaliFields);
      }

      for (let tipologiaElementiStrutturaliImpalcato of tipologiaElementiStrutturaliImpalcatoList) {
        const tipologiaElementiStrutturaliImpalcatoFields = [
          {
            field: tipologiaElementiStrutturaliImpalcato ?? {},
            fieldName:
              'RILIEVO CARATTERISTICHE GEOMETRICHE - Tipologia elementi strutturali Impalcato - N° Elementi Totali',
            key: 'n° elementi totali',
            levelName: 'Level 1 Descriptive Sheet',
          },
        ];
        this.convertNumberFields(tipologiaElementiStrutturaliImpalcatoFields);
      }
    }

    // Level 2 fields

    const level2Model =
      this.model[BridgeConstants.levels.l0]?.level2?.level2List?.[0]
        ?.modelstructure;
    if (level2Model) {
      const l2CampateList = level2Model.campateCdaStatus?.campateList ?? [];
      for (let campata of l2CampateList) {
        const l2Fields = [
          // Level 2 - S&F Fields
          {
            field:
              campata?.modelstructure?.['Strutturale e fondazionale']?.[
                'Stima del livello di vulnerabilita'
              ] ?? {},
            fieldName: 'Difettosita relativa',
            key: 'Difettosita relativa',
            levelName: 'Level 2 - S&F',
          },
          {
            field:
              campata?.modelstructure?.['Strutturale e fondazionale']?.[
                'Stima del livello di vulnerabilita'
              ] ?? {},
            fieldName: 'Luce massima campata m',
            key: 'Luce massima campata m',
            levelName: 'Level 2 - S&F',
          },
          {
            field:
              campata?.modelstructure?.['Strutturale e fondazionale']?.[
                'Stima del livello di vulnerabilita'
              ] ?? {},
            fieldName: 'N° di campate',
            key: 'N° di campate',
            levelName: 'Level 2 - S&F',
          },
          {
            field:
              campata?.modelstructure?.['Strutturale e fondazionale']?.[
                'Stima del livello di esposizione'
              ] ?? {},
            fieldName: 'Luce media della campata',
            key: 'Luce media della campata',
            levelName: 'Level 2 - S&F',
          },

          // Level 2 - Sismica Fields
          {
            field:
              campata?.modelstructure?.['Sismica']?.[
                'Valutazione del livello di pericolosità sismica'
              ] ?? {},
            fieldName: 'Accelerazione di picco al suolo',
            key: 'Accelerazione di picco al suolo',
            levelName: 'Level 2 - Sismica',
          },
          {
            field:
              campata?.modelstructure?.['Sismica']?.[
                'Stima del livello di vulnerabilità sismica'
              ] ?? {},
            fieldName: 'Luce massima campata m',
            key: 'Luce massima campata m',
            levelName: 'Level 2 - Sismica',
          },

          // Level 2 - Idraulica Fields
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName: 'a Diametro pila',
            key: 'a Diametro pila',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName: 'wa l Larghezza alveo a monte del ponte',
            key: 'wa l Larghezza alveo a monte del ponte',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName:
              'wa Larghezza complessiva alveo in corrispondenza del ponte',
            key: 'wa Larghezza complessiva alveo in corrispondenza del ponte',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName:
              'Wg l Larghezza complessiva delle golene occupata dai rilevati di accesso',
            key: 'Wg l Larghezza complessiva delle golene occupata dai rilevati di accesso',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName:
              'Wg Larghezza complessiva delle golene a monte del ponte',
            key: 'Wg Larghezza complessiva delle golene a monte del ponte',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName: 'ca Fattore di restringimento',
            key: 'ca Fattore di restringimento',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName: 'Cg fattore restringimento delle aree golenali',
            key: 'Cg fattore restringimento delle aree golenali',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName: 'ds Massima profondità di scavo',
            key: 'ds Massima profondità di scavo',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName:
              'df Profondità di posa del piano di fondazione rispetto all alveo',
            key: 'df Profondità di posa del piano di fondazione rispetto all alveo',
            levelName: 'Level 2 - Idraulica',
          },
          {
            field:
              campata?.modelstructure?.['Idraulica']?.[
                'Stima della suscettibilita legeta al rischio idraulico'
              ] ?? {},
            fieldName: 'IEL',
            key: 'IEL',
            levelName: 'Level 2 - Idraulica',
          },
        ];
        this.convertNumberFields(l2Fields);
      }
    }
  }

  private saveAttentionClassDetailsToL0(): void {
    const campateCdaStatus =
      this.model[BridgeConstants.levels.l0]?.[BridgeConstants.levels.level2]
        ?.level2List?.[0]?.modelstructure?.campateCdaStatus;

    // Getting the periodo
    const periodo = this.getPeriodoFromInspectionDate();

    // Setting default values for attentionClassDetails
    this.model[BridgeConstants.levels.l0].attentionClassDetails = {
      'CdA Globale': campateCdaStatus?.["classeDiAttenzioneDell'Opera"] ?? '',
      'Periodicita ispezione':
        campateCdaStatus?.['Globale Opera']?.['Frequenza ispettiva'] ?? '',
      'Prossima ispezione':
        campateCdaStatus?.['Globale Opera']?.['Prossima Ispezione'] ?? '',
      Periodo: periodo ?? '',
      rilevamento: campateCdaStatus?.rilevamento ?? '',
    };
  }

  private saveDefectTotalCountToL0(): void {
    // Setting default values for defectTotalCount
    this.model[BridgeConstants.levels.l0].defectTotalCount = {
      G1: 0,
      G2: 0,
      G3: 0,
      G4: 0,
      G5: 0,
      PS: 0,
    };

    const campateList =
      this.model[BridgeConstants.levels.l0].opereparzialilist?.[0]
        ?.modelstructure.campatelist;
    if (!campateList) return;

    const { defectTotalCount } = this.model[BridgeConstants.levels.l0];

    // Iterating through each campata to calculate defect count
    campateList.forEach(({ modelstructure }) => {
      // Combining all defects list in campata
      const defectsList = [].concat(
        modelstructure[`${BridgeConstants.levels.spalle}list`],
        modelstructure[`${BridgeConstants.levels.pile}list`],
        modelstructure[`${BridgeConstants.levels.archi}list`],
        modelstructure[`${BridgeConstants.levels.impalcati}list`],
        modelstructure[`${BridgeConstants.levels.giunti}list`],
        modelstructure[`${BridgeConstants.levels.apparecchiSismici}list`],
        modelstructure[`${BridgeConstants.levels.fileDiAppoggi}list`]
      );

      // Iterating through each defects list in campata
      defectsList.forEach(({ modelstructure }) => {
        // Iterating through each defect in defects list
        modelstructure['Scheda Ispezione Ponti di Livello 1'][
          'Scheda Ispezione Ponti di Livello 1 List1'
        ].forEach((defectRow) => {
          // Updating PS count
          if (defectRow.PS) defectTotalCount.PS += 1;

          // Updating G1 to G5 count based on G value and K1, K2 values
          const k1 = Number(defectRow['Estensione K1']) || 0;
          const k2 = Number(defectRow['Intensità K2']) || 0;
          if (k1 && k2 && defectRow.G) defectTotalCount[`G${defectRow.G}`] += 1;
        });
      });
    });
  }

  private syncSharedModelDataForList(
    pathsKey: string,
    updatedValue: any,
    model: any
  ): void {
    if (!updatedValue) return;

    const propPaths = this.cubeService.getDeepCopy(
      model.sharedDataForList[pathsKey]
    );
    propPaths.forEach(({ path, list, subList, syncCondition }) => {
      const shouldSync = syncCondition ? eval(syncCondition) : true;
      if (!shouldSync) return;
      const items = this.cubeService.getPropByPathList(model, list);

      items?.forEach((item) => {
        if (subList == null) {
          this.updateSharedValues(updatedValue, item, path);
        } else {
          const subItems = this.cubeService.getPropByPathList(item, subList);

          subItems?.forEach((subItem) => {
            this.updateSharedValues(updatedValue, subItem, path);
          });
        }
      });
    });
  }

  private updateBridgeDefectsKeys(campataDefectsList: any[]): void {
    campataDefectsList.forEach(({ modelstructure: modelStructure }) => {
      const pontiDiLivello =
        modelStructure['Scheda Ispezione Ponti di Livello 1'];

      // Updating Campate No to Campata No
      if (pontiDiLivello['Campate No']) {
        pontiDiLivello[BridgeConstants.partNo] = pontiDiLivello['Campate No'];
        delete pontiDiLivello['Campate No'];
      }

      // Updating Codice difetto for eventauli note
      const pontiDiLivelloList1 =
        pontiDiLivello['Scheda Ispezione Ponti di Livello 1 List1'];
      pontiDiLivelloList1[pontiDiLivelloList1.length - 1]['Codice difetto'] =
        BridgeConstants.eventualiNote;
    });
  }

  private updateCasoSectionKeys(
    casoList: any[],
    oldKey: string,
    newKey: string
  ): void {
    casoList.forEach((casoItem) => {
      if (!casoItem[oldKey]) return;

      casoItem[newKey] = casoItem[oldKey];
      delete casoItem[oldKey];
    });
  }

  private updateCodiceIopInDefects(campataDefectsList: any[]): void {
    campataDefectsList.forEach(({ modelstructure: modelStructure }) => {
      const pontiDiLivello =
        modelStructure['Scheda Ispezione Ponti di Livello 1'];

      pontiDiLivello['Codice IOP'] =
        this.model[BridgeConstants.levels.l0]['Codice IOP'];
    });
  }

  private updateFotoNameInDefects(campataDefectsList: any[]): void {
    campataDefectsList.forEach(({ modelstructure: modelStructure }) => {
      const pontiDiLivello =
        modelStructure['Scheda Ispezione Ponti di Livello 1'];
      const defectsList =
        pontiDiLivello['Scheda Ispezione Ponti di Livello 1 List1'];
      const fotoCode = pontiDiLivello.fotoCode;

      for (let i = defectsList.length - 1; i >= 0; i--) {
        const defect = defectsList[i];
        // If the defect is Codice difetto as eventauli note, then skip it
        if (defect['Codice difetto'] === BridgeConstants.eventualiNote)
          continue;

        // If the defect already has a fotoName, then skip it
        if (defect.fotoName) break;

        // Adding the fotoName to the defect
        defect.fotoName = fotoCode;

        // Updating the key of N° foto items in the defect
        if (defect['N° foto']) {
          const updatedNFoto = {};
          const nFotoValues = Object.entries(defect['N° foto']);
          nFotoValues.forEach(([fotoKey, foto]) => {
            // Removing the fotoCode from the fotoKey (including duplicates)
            const fotoKeyWithoutFotoCode = fotoKey
              .split(` - ${fotoCode}`)
              .join('');
            const nameParts = fotoKeyWithoutFotoCode.split(' - ');
            // Inserting the fotoCode at the 4th position in the name parts
            nameParts.splice(3, 0, fotoCode);
            updatedNFoto[nameParts.join(' - ')] = foto;
          });
          defect['N° foto'] = updatedNFoto;
        }
      }
    });
  }

  private updateInheritedFieldsInLevel2Campata(cdaCampata): void {
    const modelValue =
      this.model[BridgeConstants.levels.l0]?.opereparzialilist?.[0]
        ?.modelstructure;

    if (!modelValue) return;

    const {
      [BridgeConstants.level2RiskSheets.strutturaleEFondazionale]:
        strutturaleEFondazionale,
      [BridgeConstants.level2RiskSheets.sismica]: sismica,
      [BridgeConstants.level2RiskSheets.frane]: frane,
      [BridgeConstants.level2RiskSheets.idraulica]: idraulica,
    } = cdaCampata['modelstructure'];

    // Updating inherited fields in strutturale e fondazionale tab
    if (strutturaleEFondazionale) {
      const esposizione =
        strutturaleEFondazionale['Stima del livello di esposizione'];

      esposizione['Alternative stradali'] =
        this.model.l0['Rete stradale'][
          'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
        ][
          'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico'
        ];
      /* TODO: what about the following fields:
        1. Luce massima campata [m]
        2. N° campate
        3. Luce media della campata
      */
    }

    // Updating inherited fields in sismica tab
    if (sismica) {
      const pericolosita =
        sismica['Valutazione del livello di pericolosità sismica'];
      const vulnerabilita =
        sismica['Stima del livello di vulnerabilità sismica'];

      pericolosita['Accelerazione di picco al suolo'] =
        this.model.l0['Localizzazione']['Sismicità dell’area'];

      const tipoStrutturale = modelValue['Tipologia strutturale'];
      if (tipoStrutturale['Schema isostatico']) {
        vulnerabilita['Schema statico'] = 'Schema isostatico';
      } else if (tipoStrutturale['Schema iperstatico']) {
        vulnerabilita['Schema statico'] = 'Schema iperstatico';
      }
      /* TODO: what about the following fields:
        1. Luce massima campata [m]
        2. Elementi di vulnerabilità sismica
      */
    }

    // Updating inherited fields in frane tab
    if (frane) {
      const suscettibilita =
        frane['Stima Del Livello Di Suscettibilità Legato Al Rischio Frane'];

      const rischioFrane = modelValue['Rischio frane'];
      const parametroDelloStatoFrane =
        rischioFrane[
          'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali'
        ];
      suscettibilita['Instabilità del versante'] =
        parametroDelloStatoFrane[
          'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali - radio'
        ];
      switch (true) {
        case parametroDelloStatoFrane[
          'Attivo al momento del rilevo o con segni di movimento in atto'
        ] ||
          parametroDelloStatoFrane[
            'Sospesa attivo nell ultimo ciclo stagionale'
          ] ||
          parametroDelloStatoFrane[
            'Quiescente non attivo da più di un ciclo stagionale ma riattivabile'
          ]:
          suscettibilita['Stato di attività'] = 'Attiva';

          break;

        case parametroDelloStatoFrane[
          'Inattiva non attivo da diversi cicli stagionali'
        ]:
          suscettibilita['Stato di attività'] = 'Inattiva';

          break;

        case parametroDelloStatoFrane['Stabilizzata']:
          suscettibilita['Stato di attività'] = 'Stabilizzata';

          break;
      }
      suscettibilita['Massima velocità attesa'] =
        rischioFrane[
          'Parametro della massima velocità attesa in funzione della tipologia di frana in atto o potenziale PV - radio'
        ];
      suscettibilita['Magnitudo attesa su base volumetrica in m3'] =
        rischioFrane[
          'Parametro della magnitudo su base volumetrica in metri cubi PM frane riconosciute / potenziali - radio'
        ];
      suscettibilita['Affidabilità della valutazione'] =
        rischioFrane['Affidabilità complessiva della valutazione'][
          'Affidabilità complessiva della valutazione - radio'
        ];
    }

    // Updating inherited fields in idraulica tab
    if (idraulica) {
      const suscettibilita =
        idraulica['Stima della suscettibilita legeta al rischio idraulico'];
      const vulnerabilita =
        idraulica['Stima della vulnerabilita legeta al rischio idraulico'];

      const rischioIdraulico = modelValue['Rischio idraulico'];
      const tipologiaDiReticolo = rischioIdraulico['Tipologia di reticolo'];
      switch (true) {
        case tipologiaDiReticolo['Reticolo principale']:
          suscettibilita['Corso d acqua'] = 'principale';

          break;

        case tipologiaDiReticolo['Reticolo secondario'] ||
          tipologiaDiReticolo['Reticolo artificiale di scolo']:
          suscettibilita['Corso d acqua'] = 'secondario';

          break;

        default:
          suscettibilita['Corso d acqua'] = '-';

          break;
      }
      const tipoDiAlveo = rischioIdraulico['Tipologia di alveo'];
      suscettibilita['wa l Larghezza alveo a monte del ponte'] =
        tipoDiAlveo[
          'Larghezza complessiva dell’alveo inciso occupata dall’ingombro di pile e spalle wa,l'
        ];
      suscettibilita[
        'wa Larghezza complessiva alveo in corrispondenza del ponte'
      ] =
        tipoDiAlveo[
          'Larghezza complessiva dell’alveo inciso a monte del ponte wa'
        ];
      suscettibilita[
        'Wg l Larghezza complessiva delle golene occupata dai rilevati di accesso'
      ] =
        tipoDiAlveo[
          'Larghezza complessiva delle golene occupata dai rilevati di accesso, dalle spalle e dalle pile wg,l'
        ];
      suscettibilita[
        'Wg Larghezza complessiva delle golene a monte del ponte'
      ] =
        tipoDiAlveo['Larghezza complessiva delle golene a monte del ponte wg'];
      suscettibilita['ds Massima profondità di scavo'] =
        rischioIdraulico['Caratteristiche Alveo'][
          'Massima profondità di scavo ds'
        ];
      suscettibilita[
        'df Profondità di posa del piano di fondazione rispetto all alveo'
      ] =
        rischioIdraulico['Caratteristiche Alveo'][
          'Profondità di posa del piano di fondazione rispetto all’alveo df'
        ];

      const indicareEventuale =
        rischioIdraulico['Caratteristiche Alveo'][
          'Indicare l’eventuale sussistenza delle seguenti condizioni'
        ];
      vulnerabilita[
        'Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte'
      ] = indicareEventuale[
        'Evidenza di presenza di fondazioni profonde delle pile e delle spalle del ponte'
      ]
        ? 'SI'
        : 'NO';
      vulnerabilita[
        'Evidenza di fenomeni di abbassamento generalizzato dell alveo a monte e a valle del ponte 1'
      ] = indicareEventuale[
        'Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte'
      ]
        ? 'SI'
        : 'NO';
      vulnerabilita[
        'Ponte posizionato in tratto di alveo avente sensibile curvatura'
      ] = indicareEventuale[
        'Ponte posizionato in tratto di alveo avente sensibile curvatura'
      ]
        ? 'SI'
        : 'NO';
      vulnerabilita[
        'Evidenza di fenomeni di abbassamento generalizzato dell alveo a monte e a valle del ponte 2'
      ] = indicareEventuale[
        'Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte'
      ]
        ? 'SI'
        : 'NO';
      vulnerabilita[
        'Presenza di accumuli di detriti o materiale flottante a monte della pila'
      ] = indicareEventuale[
        'Presenza di accumuli di detriti o materiale flottante a monte della pila'
      ]
        ? 'SI'
        : 'NO';
      vulnerabilita['Tendenza dell alveo alla divagazione planimetrica'] =
        indicareEventuale['Tendenza dell’alveo alla divagazione planimetrica']
          ? 'SI'
          : 'NO';
      vulnerabilita[
        'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte'
      ] = indicareEventuale[
        'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte'
      ]
        ? 'SI'
        : 'NO';
      vulnerabilita[
        'Presenza di una briglia di protezione immediatamente a valle del ponte'
      ] = indicareEventuale[
        'Presenza di una briglia di protezione immediatamente a valle del ponte'
      ]
        ? 'SI'
        : 'NO';
    }
  }

  private updateKeys(
    oldKeyPathValues: any[],
    newKeyPath: string[][],
    startingObject?: any
  ): void {
    oldKeyPathValues.forEach((value, index) => {
      const pathParts = newKeyPath[index];
      let currentObject = startingObject ?? this.model;

      pathParts.forEach((part, index) => {
        currentObject = currentObject[part] ?? {};

        if (index === pathParts.length - 1) {
          currentObject[part] = value;
        }
      });
    });
  }

  private updateKeysForArray(
    parentItem: any,
    parentPath: any[],
    oldKey: string,
    newKey: string
  ): void {
    parentPath.forEach((path) => {
      if (!parentItem[path]) return;

      parentItem = parentItem[path];
    });

    if (Object.keys(parentItem).includes(oldKey)) {
      parentItem[newKey] = parentItem[oldKey];
      delete parentItem[oldKey];
    }
  }

  private updateRepeatSectionKeys({
    list,
    newKeys,
    oldKeys,
    radioKeys,
  }: RepeatSectionMigrationItems): void {
    // Iterating through repeat section
    list.forEach((repeatItem) => {
      // Iterating through each key to be changed in repeat item
      oldKeys.forEach((oldKey, i) => {
        if (!repeatItem[oldKey]) return;

        const newKey = newKeys[i];
        // Setting the value from old key to the new key
        repeatItem[newKey] = repeatItem[oldKey];

        // Updating radio key
        if (repeatItem[newKey] && repeatItem[oldKey][radioKeys[i]]) {
          repeatItem[newKey]['radio'] = repeatItem[oldKey][radioKeys[i]];
        }

        if (oldKey !== newKey) delete repeatItem[oldKey];
      });
    });
  }

  private updateSharedValues(
    updatedValue: any,
    data: any,
    path: string[]
  ): void {
    const pathDeepCopy = this.cubeService.getDeepCopy(path);
    const pathValue = pathDeepCopy;
    const pathCopy = [...pathValue];
    const key = pathCopy.pop();
    const parent = this.cubeService.getPropByPathList(data, pathCopy);

    if (parent != null && parent[key] != updatedValue) {
      parent[key] = updatedValue;
    }
  }
}
